#page {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.pro-icon-wrapper {
  background-color: #0000007f;
  border-radius: 30px;
  padding: 15px;
  color: aliceblue;
}

.pro-sidebar .pro-menu .pro-menu-item.active .pro-icon-wrapper {
  background-color: #969696;
  border-radius: 30px;
  padding: 15px;
  color: rgb(0, 0, 0);
}

.pro-sidebar .pro-menu .pro-menu-item.active .pro-item-content {
  color: #fffffffb;
}

.pro-sidebar .pro-menu .pro-menu-item .pro-item-content {
  font-size: 14px;
  color: #a0a0a0;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
  overflow-y: hidden;
}

.pro-sidebar-content {
  overflow-y: auto;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item
  > .pro-icon-wrapper {
  display: inline-block !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item
  .pro-arrow-wrapper {
  display: inline-block !important;
}

.pro-sidebar
  .pro-menu
  .pro-menu-item.pro-sub-menu
  .pro-inner-list-item
  .pro-inner-item:before {
  content: none !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
  padding-bottom: auto;
}

#main {
  height: 100%;
  width: 100%;
  overflow-x: scroll;
}

#page .pro-sidebar-inner .pro-sidebar-layout .active {
  border-radius: 8px !important;
  margin: 3px;
}

.bar {
  position: inherit !important;
  background-color: #dbdbdb !important;
  background-image: linear-gradient(
    to right,
    #e2000f,
    #e2000f,
    #e2000f,
    #e2000f,
    #e2000f
  );
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  width: 100%;
}

.tool {
  display: flex;
  align-items: center;
  background-color: #e2000f;
  height: 65px;
}

#menu {
  z-index: 1101;
  height: 100vh;
}

.menuhidder {
  @media (max-width: 450px) {
    display: none;
  }
}

.absoluteMenuButton {
  position: absolute;
  bottom: 0px;
  left: 0;
  padding: 4px;
  color: white;
  cursor: pointer;
  z-index: 10;
  border-radius: 0 10px 0 0;
  background-color: #e2000f;
  @media (min-width: 450px) {
    display: hidden;
  }
}

#smalltext {
  margin-left: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

#text {
  margin-left: 90px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
}

.title {
  color: white !important;
  font-weight: bold !important;
  font-size: 24px !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  /*Para centrar*/
  padding-right: 50px;
  /*Para no romper el header*/
  max-height: 65px;
  text-align: center;
  align-self: center;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 95vw;
}

.language {
  color: white !important;
  @media (max-width: 600px) {
    display: none;
  }
}

#appContent {
  height: calc(100% - 65px);
  width: calc(100%);
}

.icon {
  display: flex;
}

.navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-around !important;
  align-items: center;
}

.navBar3 {
  color: rgb(70, 70, 70) !important;
  cursor: pointer;
}

.sidebarMenuItemDisabled > * {
  cursor: default !important;
}

.sidebarMenuItemDisabled > * > * > * {
  animation: none !important;
}

.sidebarMenuItemDisabled > .pro-inner-item > .pro-icon-wrapper {
  opacity: 0.3 !important;
}

/*En caso de querer usar custom scroll cambiar PerfectScroll por div y poner clasename*/
.mainCustomScrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.mainCustomScrollbar::-webkit-scrollbar-track {
  background: #ebebeb;
  border-radius: 6px;
}
.mainCustomScrollbar::-webkit-scrollbar-thumb {
  background: #57575659;
  border-radius: 6px;
}

.mainCustomScrollbar::-webkit-scrollbar-thumb:hover {
  background: #888;
}

/* Quitar el scroll en el calendario de PM en Planta (Afecta a toda la libreria) */
.calendar {
  overflow: visible !important;
  padding: 0 !important;
}
