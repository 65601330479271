@font-face {
    font-family: 'Myriad Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Regular'), url('/fonts/MyriadPro-Regular.woff') format('woff');
    }

    @font-face {
    font-family: 'Myriad Pro Condensed Italic';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Condensed Italic'), url('/fonts/MyriadPro-It.woff') format('woff');
    }

    @font-face {
    font-family: 'Myriad Pro Light';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Light'), url('/fonts/MyriadPro-Light.woff') format('woff');
    }

    @font-face {
    font-family: 'Myriad Pro Semibold';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Semibold'), url('/fonts/MyriadPro-Semibold.woff') format('woff');
    }

    @font-face {
    font-family: 'Myriad Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Myriad Pro Bold'), url('/fonts/MyriadPro-Bold.woff') format('woff');
    }
    