.swal2-actions {
  display: flex;
  justify-content: flex-end;
}

.swal2-confirm {
  background-color: #e2000f !important;
  color: #ffffff !important;
  border: none !important;
  font-weight: "bold" !important;
  text-transform: none !important;
  border-radius: 3px !important;
  margin-left: 20px !important;
  min-width: 120px !important;
}

.swal2-cancel {
  background-color: #ffffff !important;
  color: #e2000f !important;
  outline: auto !important;
  outline-color: #e2000f;
  border: 1px !important;
  border-color: #e2000f !important;
  font-weight: "bold" !important;
  text-transform: none !important;
  border-radius: 2px !important;
  min-width: 120px !important;
}

.swal2-input {
  color: #303030 !important;
  background-color: #ffffff !important;
  border: 1px solid #303030 !important;
  border-radius: 0 !important;
}
